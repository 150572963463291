<script setup lang="ts">
import { clamp } from '~/utils/number'

const props = withDefaults(defineProps<{
  steps: string[]
  index?: number
}>(), {
  index: 0,
})

const theIndex = computed(() => clamp(props.index, props.steps))
</script>

<template>
  <nav data-ui="UiSteps" aria-label="Progress">
    <ol role="list" class="flex items-start font-bold text-base">
      <li
        v-for="(step, stepIndex) in steps"
        :key="step"
        class="relative"
        :class="{ 'pr-8': stepIndex !== steps.length - 1 }"
      >
        <!-- line -->
        <div class="absolute inset-x-0 flex items-start top-2.5" aria-hidden="true">
          <div class="h-[1px] w-full bg-[#d0cbc3]" />
        </div>

        <!-- icon -->
        <div class="relative flex flex-col w-fit items-center justify-center border-[#d0cbc3]">
          <!-- completed -->
          <div v-if="stepIndex < theIndex" class="h-5 w-5 rounded-full bg-green-600 flex items-center justify-center border border-forgd-bgd-600" aria-hidden="true">
            <UIcon name="i-heroicons-check" class="h-3 w-3 text-white" />
          </div>

          <!-- current -->
          <div v-else-if="stepIndex === theIndex" class="h-5 w-5 rounded-full flex items-center justify-center border border-forgd-bgd-600 bg-forgd-bgd-200 overflow-hidden" aria-hidden="true">
            <div class="h-[14px] w-[14px] rounded-full bg-forgd-primary-300 shadow-current" />
          </div>

          <!-- upcoming -->
          <div v-else class="h-5 w-5 rounded-full bg-forgd-bgd-600" aria-hidden="true" />

          <!-- text -->
          <div
            class="mt-1 text-center"
            :class="{
              'text-green-600': stepIndex < theIndex,
              'text-forgd-primary-300': stepIndex === theIndex,
              'text-forgd-bgd-600': stepIndex > theIndex,
            }"
          >
            {{ step }}
          </div>
        </div>
      </li>
    </ol>
  </nav>
</template>

<style scoped>
.shadow-current { box-shadow: 0px 0px 5px #0077FF; }
</style>
